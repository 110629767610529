
export class PowerEvent {
    productionKWh: string;
    consumptionKWh: string;
    exportKWh: string;
    peakPower: string;
    dollarsAvoided: string;
    dollarsCosting: string;
    dollarsEarning: string;

    historyIntervals: string[];
    consumptionHistory: string[];
    productionHistory: string[];


    //solar: string;
    //grid: string;
    //cons: string;
    //avoidCost: string;
    //feedIn: string;
  };
  