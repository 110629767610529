import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Higgins.one';
  
}


//<img width="50" alt="People Logo" src="../assets/images/peopleLogo.png" />

//<button mat-raised-button color="primary" (click)="onHome()">
//<mat-icon>home</mat-icon>
//</button>
