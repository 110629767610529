<div class="blob">
    <mat-card>
        <mat-card-header>
            <mat-card-title>People Functions</mat-card-title>
            <mat-card-subtitle>Create and edit People details for displaying birthdays</mat-card-subtitle>
            <mat-card-content></mat-card-content>
        </mat-card-header>
        <img mat-card-sm-image src="../assets/images/herb.jpg" alt="Herb">
        <mat-card-content>
            <p>
              <i>List, detail and birthdays. Angular with AWS technologies.</i>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <a mat-raised-button color="primary" href="/list">
                <mat-icon>people</mat-icon>&nbsp;List
              </a>
              <a mat-raised-button color="accent" href="/peep">
                <mat-icon>person_add</mat-icon>&nbsp;Add person 
              </a>
              <a mat-raised-button color="warn" href="/birthdays">
                <mat-icon>cake</mat-icon>&nbsp;Birthdays 
              </a>
          </mat-card-actions>
    </mat-card>
    <mat-card *ngIf="powerEnabled==true">
        <mat-card-header>
            <mat-card-title>House Functions</mat-card-title>
            <mat-card-subtitle>Some more stuff</mat-card-subtitle>
            <mat-card-content></mat-card-content>
        </mat-card-header>
        <img mat-card-sm-image src="../assets/images/peopleLogo.png" alt="People Logo">
        <mat-card-content>
            <p>
              BLAH BLAH BLAH.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <a mat-raised-button color="primary" href="/power">
                <mat-icon>power</mat-icon>&nbsp;power
              </a>
          </mat-card-actions>
    </mat-card>
</div>