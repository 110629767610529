<table *ngIf="(person)&&(debug==true)">
    <tr>
        <th>Person</th>
    </tr>
    <tr>
        <td>
            id = {{person.id}}<br>
            FirstName = {{person.firstName}}<br>
            FamilyName = {{person.familyName}}<br>
            PreferredName = {{person.preferredName}}<br>
            MaidenName = {{person.maidenName}}<br>
            Sex = {{person.birthSex}}<br>
            dob = {{person.dob}} dod={{person.dod}} -- Living = {{person.living}}<br>
            motherid = {{person.motherid}}<br> 
            fatherid = {{person.fatherid}}<br>
        </td>
    </tr>
</table>
<mat-card>
    <mat-card-content *ngIf="person">
        <mat-card-title>{{getCardTitle()}}</mat-card-title>
        <mat-form-field appearance="fill" style="width:220px">
            <mat-label>First name</mat-label>
            <input matInput placeholder="Barry" (change)="OnFieldChange()" [(ngModel)]="person.firstName" required disabled="{{getFirstNameDisabled()}}">
            <mat-icon *ngIf="person.firstName==''" matPrefix>star_border</mat-icon>
            <mat-icon *ngIf="person.firstName!=''" matPrefix>star</mat-icon>
            <mat-hint>First or common name for the person</mat-hint>
        </mat-form-field>
        
        <mat-form-field appearance="fill" style="width:220px">
            <mat-label>Family name</mat-label>
            <input matInput placeholder="McDuck" (change)="OnFieldChange()" [(ngModel)]="person.familyName" required disabled="{{getFamilyNameDisabled()}}">
            <mat-icon *ngIf="person.familyName==''" matPrefix>star_border</mat-icon>
            <mat-icon *ngIf="person.familyName!=''" matPrefix>star</mat-icon>
            <mat-hint>Family or surname for the person</mat-hint>
        </mat-form-field>
        <br>
      
        <mat-form-field appearance="fill" style="width:220px">
            <mat-label>Oher Names</mat-label>
            <input matInput placeholder="John Joseph" (change)="OnFieldChange()" [(ngModel)]="person.otherNames"
                disabled="{{getOtherNamesDisabled()}}">
            <mat-hint>Other names of the person</mat-hint>
        </mat-form-field>
        
        <mat-form-field appearance="fill" style="width:220px">
            <mat-label>Preferred Name</mat-label>
            <input matInput placeholder="Bazza" (change)="OnFieldChange()" [(ngModel)]="person.preferredName"
                disabled="{{getPreferredNameDisabled()}}">
            <mat-hint>Common nickname for the person</mat-hint>
        </mat-form-field>
        <br>

        <mat-form-field appearance="fill" style="width:220px">
            <mat-label>Registered Sex at birth</mat-label>
            <mat-select [(ngModel)]="person.birthSex" name="sex" required>
              <mat-option *ngFor="let sexOption of sexOptions" [value]="sexOption">
                {{sexOption}}
              </mat-option>
            </mat-select>
            <mat-hint>Sex of the persons birth</mat-hint>
          </mat-form-field>
          
          <mat-form-field *ngIf="person.birthSex=='Female'" appearance="fill" style="width:220px">
            <mat-label>Maiden Name</mat-label>
            <input matInput placeholder="Smith" (change)="OnFieldChange()" [(ngModel)]="person.maidenName" disabled="false">
            <mat-hint>Name before marriage if changed</mat-hint>
        </mat-form-field>
        <br>

        <mat-form-field *ngIf="person.dob=='Recorded'" appearance="fill" style="width:180px">
            <mat-label>Date of Birth</mat-label>
            <input matInput placeholder="11-11-1911"  [(ngModel)]="person.dob" required>
            <mat-icon matPrefix>star</mat-icon>
            <mat-hint>Date of Birth</mat-hint>
        </mat-form-field>
        
        <mat-form-field *ngIf="person.dob!='Recorded'" appearance="fill" style="width:180px">
            <mat-label>Date of Birth</mat-label>
            <input matInput placeholder="11-11-1911" type="date" (change)="OnFieldChange()" [(ngModel)]="person.dob" required>
            <mat-icon *ngIf="!isDobDefined()" matPrefix>star_border</mat-icon>
            <mat-icon *ngIf="isDobDefined()" matPrefix>star</mat-icon>
        </mat-form-field>
        &nbsp;
        <mat-checkbox [(ngModel)]="bLiving" (change)="OnChangeLiving()">Living</mat-checkbox>
        &nbsp;
        <mat-form-field *ngIf="!bLiving" appearance="fill" style="width:180px">
            <mat-label>Date of Death</mat-label>
            <input matInput placeholder="1911-11-11" type="date" (change)="OnFieldChange()" [(ngModel)]="person.dod">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" style="width:452px">
            <mat-label>Notes</mat-label>
            <input matInput placeholder="Random thoughts" (change)="OnFieldChange()" [(ngModel)]="person.notes" disabled="false">
            <mat-hint>General notes about the person</mat-hint>
        </mat-form-field>
    </mat-card-content>
</mat-card>
<br>