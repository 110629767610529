import { Component, OnInit } from '@angular/core';

import { timer } from 'rxjs';

import { PowerService } from '../power.service';

@Component({
  selector: 'app-power',
  templateUrl: './power.component.html',
  styleUrls: ['./power.component.css']
})
export class PowerComponent implements OnInit {

  //tmr = timer(200, 20000);
  powerEvt;
  debug = true;
  fProductionKWh: number;
  fConsumptionKWh: number;
  fExportKWh: number;
  bPeakPower: boolean;
  dDollarsAvoided: number;
  dDollarsCosting: number;
  dDollarsEarning: number; 


  blah = 0;

  constructor(private pwrSrc: PowerService ) { }

  ngOnInit(): void {
    console.info("ngOnInit");
    //this.tmr.subscribe(val => this.getEnvoyState(val));
    this.debugClick();
  }

  public ngOnDestroy(): void {
    console.info("ngOnDestroy");
  }

  debugClick(){
    this.blah += 1;
    this.getEnvoyState(this.blah);
  }

  getEnvoyState(i): void {
//    this.envoyService.getEnvoy().subscribe(envoyDetails => this.envoyDetails = envoyDetails);
    this.powerEvt = this.pwrSrc.getPowerValues(i);
    // Convert for convient use
    this.fProductionKWh = parseFloat(this.powerEvt.productionKWh);
    this.fConsumptionKWh = parseFloat(this.powerEvt.consumptionKWh);
    this.fExportKWh = parseFloat(this.powerEvt.exportKWh);
    this.bPeakPower = this.powerEvt.peakPower == "true";
    this.dDollarsAvoided = parseFloat(this.powerEvt.dollarsAvoided);
    this.dDollarsCosting = parseFloat(this.powerEvt.dollarsCosting);
    this.dDollarsEarning = parseFloat(this.powerEvt.dollarsEarning); 
  }

  fmtPower(f): string {
    var ret;

    if (f == 0)
      ret = "None";
    else{
      if (f >= 1000.0){
        ret = (f / 1000.0).toFixed(1) + " kWh";
      }
      else{
        ret = f.toString() + " Wh";
      }
    }
    return ret;
  }

  getSolarGenerating(){
    return this.fmtPower(this.fProductionKWh);
  }

  isGenerating() : boolean {
    if (this.fProductionKWh == 0)
      return(false);
    return (true);
  }

  getSolarSelfConsuming(){
    var fRet = 0;
    if (this.fProductionKWh > 0){
      if (this.fProductionKWh >= this.fConsumptionKWh){
        fRet = this.fConsumptionKWh; 
      }
      else{
        fRet = this.fProductionKWh;
      }
    }
    return this.fmtPower(fRet);
  }

  getSolarExporting(){
    return this.fmtPower(this.fExportKWh);
  }

  getPowerConsumption(){
    return this.fmtPower(this.fConsumptionKWh);
  }

  getPowerConsumptionSolarPerc(){
    var fSC = 0;
    if (this.fProductionKWh > 0){
      if (this.fProductionKWh >= this.fConsumptionKWh){
        fSC = this.fConsumptionKWh; 
      }
      else{
        fSC = this.fProductionKWh;
      }
    }
    var res = (fSC / this.fConsumptionKWh) * 100.0;
    return res.toFixed(0);

  }

  getPowerConsumptionGridPerc(){
    var fG = 0;
    if (this.fProductionKWh > 0){
      if (this.fProductionKWh < this.fConsumptionKWh){
        fG = this.fConsumptionKWh - this.fProductionKWh; 
      }
    }
    else{
      fG = this.fConsumptionKWh;
    }
    var res = (fG / this.fConsumptionKWh) * 100.0;
    return res.toFixed(0);

  }

/////////////////////////////////////////////////////////////////

  getGridImporting(){
    var ret;
    var fG = parseFloat(this.powerEvt.grid);
    var fS = parseFloat(this.powerEvt.solar);
    var f;

    if (fG > fS){
      f = fG - fS;
      if (f >= 1000.0){
        ret = (f / 1000.0).toFixed(1).toString() + " KWh";
      }
      else{
        ret = f.toString() + " Wh";
      }
    }
    else{
      ret = "0 Wh";
    }

    return ret;
  }

  isImporting() : boolean {
    var fG = parseFloat(this.powerEvt.grid);
    var fS = parseFloat(this.powerEvt.solar);

    if (fG > fS)
      return true;
    else
      return false;
  }

  getGridExporting(){
    var ret;
    var fG, fS, fE;

    fG = parseFloat(this.powerEvt.grid);
    fS = parseFloat(this.powerEvt.solar);

    if (fS > fG){
      fE = fS - fG;

      if (fE >= 1000.0){
        ret = (fE / 1000.0).toFixed(1) + " KWh";
      }
      else{
        ret = fE + " Wh";
      }
    }
    else{
      ret = "None"
    }
  
    return ret;
  }

  isExporting() : boolean {
    var fG, fS;

    fG = parseFloat(this.powerEvt.grid);
    fS = parseFloat(this.powerEvt.solar);

    if (fS > fG)
      return true;
    else
      return false;
  }

  getConsumptionFromSolar(): string{
    var fG, fS;
    var f;

    fG = parseFloat(this.powerEvt.grid);
    fS = parseFloat(this.powerEvt.solar);
    f = 0;

    if (fS > 0){
      if (fG < fS){
        f = fG;
      }
      else{
        f = fS;
      }
    }
    return f.toString();
  }

  getConsumptionFromGrid(): string{
    var fG, fS;
    var f;

    fG = parseFloat(this.powerEvt.grid);
    fS = parseFloat(this.powerEvt.solar);
    f = 0;

    if (fG > 0){
      if (fS < fG){
        f = fG;
      }
      else{
        f = fS;
      }
    }
    return f.toString();
  }


}
