import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { PeoplesEvent } from "./peoples-event";
import { PeoplesPutResult } from "./peoples-put-result";

import { strict } from 'assert';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: 'root'
})
export class PeoplesService {

  //private peoplesUrl = "http://www.mocky.io/v2/5e9191383300008a00e9cf42";
  private peoplesUrl = "https://c9i8u0o5hb.execute-api.ap-southeast-2.amazonaws.com/DEV/peoples";

  constructor(private http: HttpClient) { }

  getPeoples(): Observable<PeoplesEvent[]> {
    //const url = `${this.peoplesUrl}?date=${str}&daysBefore=${daysBefore}&daysAfter=${daysAfter}&allFlag=${allFlag}&generations="${generations}"`;
    const url = `${this.peoplesUrl}`;
    console.info("getPeoples: URL = " + url);
    return this.http.get<PeoplesEvent[]>(url);

    // const url = `${this.birthdayUrl}?fake=${"XX"}`;
    // return this.http.get<BirthdayEvent[]>(url).pipe(
    //   tap(_ => this.log(`fetched birthdays`)),
    //   catchError(this.handleError<BirthdayEvent>(`getBirthdays`))
    // );
  }

  getPeoplesMatchingPeople(familyName, sex): Observable<PeoplesEvent[]> {
 
    //const url = `${this.peoplesUrl}?date=${str}&daysBefore=${daysBefore}&daysAfter=${daysAfter}&allFlag=${allFlag}&generations="${generations}"`;
    const url = `${this.peoplesUrl}?familyName=${familyName}&birthSex=${sex}`;
    //console.info("getPeoples: URL = " + url);
    return this.http.get<PeoplesEvent[]>(url);

    // const url = `${this.birthdayUrl}?fake=${"XX"}`;
    // return this.http.get<BirthdayEvent[]>(url).pipe(
    //   tap(_ => this.log(`fetched birthdays`)),
    //   catchError(this.handleError<BirthdayEvent>(`getBirthdays`))
    // );
  }
  
  getPerson(id): Observable<PeoplesEvent[]> {
    //const url = `${this.peoplesUrl}?date=${str}&daysBefore=${daysBefore}&daysAfter=${daysAfter}&allFlag=${allFlag}&generations="${generations}"`;
    //const url = `${this.peoplesUrl}?id=${id}`;
    const url = `${this.peoplesUrl}/${id}`;
    //console.info("getPeoples: URL = " + url);
    return this.http.get<PeoplesEvent[]>(url);

    // const url = `${this.birthdayUrl}?fake=${"XX"}`;
    // return this.http.get<BirthdayEvent[]>(url).pipe(
    //   tap(_ => this.log(`fetched birthdays`)),
    //   catchError(this.handleError<BirthdayEvent>(`getBirthdays`))
    // );
  }

  putPerson(person: PeoplesEvent): Observable<PeoplesPutResult> {
    const url = `${this.peoplesUrl}`;
    console.info("putPerson: URL = " + url);
    return this.http.post<PeoplesPutResult>(url, person, httpOptions);
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ElecDetailService message with the MessageService */
  private log(message: string) {
    console.info(message);
    //this.messageService.add('ElecDetailService: ' + message);
  }
}
