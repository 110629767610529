<div class="mat-app-background basic-container">
    <div *ngIf="person" >
        <div *ngIf="debug==true">
            <table>
                <tr>
                    <th>Person</th>
                    <th>Mother</th>
                    <th>Father</th>
                </tr>
                <tr>
                    <td>
                        id = {{person.id}}<br>
                        FirstName = {{person.firstName}}<br>
                        FamilyName = {{person.familyName}}<br>
                        PreferredName = {{person.preferredName}}<br>
                        MaidenName = {{person.maidenName}}<br>
                        Sex = {{person.birthSex}}<br>
                        dob = {{person.dob}} dod={{person.dod}} -- Living = {{person.living}}<br>
                        motherid = {{person.motherid}}<br> 
                        fatherid = {{person.fatherid}}<br>
                        notes = {{person.notes}}<br>
                    </td>
                    <td>
                        id = {{mother.id}}<br>
                        FirstName = {{mother.firstName}}<br>
                        FamilyName = {{mother.familyName}}<br>
                        PreferredName = {{mother.preferredName}}<br>
                        MaidenName = {{mother.maidenName}}<br>
                        Sex = {{mother.birthSex}}<br>
                        dob = {{mother.dob}} dod={{mother.dod}} -- Living = {{mother.living}}<br>
                        motherid = {{mother.motherid}}<br> 
                        fatherid = {{mother.fatherid}}<br>
                        notes = {{mother.notes}}<br>
                    </td>
                    <td>
                        id = {{father.id}}<br>
                        FirstName = {{father.firstName}}<br>
                        FamilyName = {{father.familyName}}<br>
                        PreferredName = {{father.preferredName}}<br>
                        MaidenName = {{father.maidenName}}<br>
                        Sex = {{father.birthSex}}<br>
                        dob = {{father.dob}} dod={{father.dod}} -- Living = {{father.living}}<br>
                        motherid = {{father.motherid}}<br> 
                        fatherid = {{father.fatherid}}<br>
                        notes = {{father.notes}}<br>
                    </td>
                </tr>
            </table>
            <br>
        </div>
        <app-peoples-base-details [person]='person' (peepUpdateEvent)="peepUpdateEvent($event)"></app-peoples-base-details>
        <br>
        <div>
            <mat-tab-group>
                <mat-tab label="Mother"> 
                    <app-reli-detail [person]='person' [reliType]='motherConst' [reliId]='person.motherid' [reli]='mother' (reliUpdateEvent)="motherUpdateEvent($event)"></app-reli-detail>
                </mat-tab>
                <mat-tab label="Father"> 
                    <app-reli-detail [person]='person' [reliType]='fatherConst' [reliId]='person.fatherid' [reli]='father' (reliUpdateEvent)="fatherUpdateEvent($event)"></app-reli-detail>
                </mat-tab>
             </mat-tab-group>
        </div>
        <br>
        <mat-card>
            <mat-card-content>
                <mat-card-title>Overview</mat-card-title>
                <mat-label>Complete: </mat-label>
                <mat-icon *ngIf="person.firstName==''" matPrefix>star_border</mat-icon>
                <mat-icon *ngIf="person.firstName!=''" matPrefix>star</mat-icon>
                <mat-icon *ngIf="person.familyName==''" matPrefix>star_border</mat-icon>
                <mat-icon *ngIf="person.familyName!=''" matPrefix>star</mat-icon>
                <mat-icon *ngIf="!isDobDefined()" matPrefix>star_border</mat-icon>
                <mat-icon *ngIf="isDobDefined()" matPrefix>star</mat-icon>
                <mat-icon *ngIf="motherDefined()" matPrefix>star</mat-icon>
                <mat-icon *ngIf="!motherDefined()" matPrefix>star_border</mat-icon>
                <mat-icon *ngIf="fatherDefined()" matPrefix>star</mat-icon>
                <mat-icon *ngIf="!fatherDefined()" matPrefix>star_border</mat-icon>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="primary" (click)="onClickSave()" >Save</button>
                <button mat-raised-button color="warn" (click)="onClickCancel()">Cancel</button>
                <button mat-raised-button color="link" (click)="gotoList()">People List...</button>
                <br>
                <p *ngIf="savingStatus">{{savingStatus}}</p>
            </mat-card-actions>
        </mat-card>
    </div>
</div>