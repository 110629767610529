import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
//import * as pluginAnnotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-power-cons-chart',
  templateUrl: './power-cons-chart.component.html',
  styleUrls: ['./power-cons-chart.component.css']
})
export class PowerConsChartComponent implements OnInit {

  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40, 33, 44, 33], label: 'Power Consumption' }
  ];
  public lineChartLabels: Label[] = ['2:33:15', '2:33:20', '2:33:25', '2:33:30', '2:33:35', '2:33:40', '2:33:45', '2:33:50', '2:33:55'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    showLines: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  //public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() { }

  ngOnInit(): void {
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  // public changeColor() {
  //   this.lineChartColors[0].borderColor = 'green';
  //   this.lineChartColors[0].backgroundColor = `rgba(0, 255, 0, 0.3)`;
  // }
}

// <div class="flex" style="padding:5px">
//     <div class="flex-item">
//       <div style="display: block;">
//       <canvas baseChart width="300" height="100"
//                   [datasets]="lineChartData"
//                   [labels]="lineChartLabels"
//                   [options]="lineChartOptions"
//                   [colors]="lineChartColors"
//                   [legend]="lineChartLegend"
//                   [chartType]="lineChartType"
//                   [plugins]="lineChartPlugins"
//                   (chartHover)="chartHovered($event)"
//                   (chartClick)="chartClicked($event)"></canvas>
//       </div>
//     </div>
//   </div>