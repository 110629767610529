import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";


import { PowerEvent } from './power-event';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};


@Injectable({
  providedIn: 'root'
})
export class PowerService {

  constructor(private http: HttpClient) { }

  //getPowerValues(): Observable<PowerEvent> {
    getPowerValues(i): PowerEvent {
    //const url = `${this.peoplesUrl}?date=${str}&daysBefore=${daysBefore}&daysAfter=${daysAfter}&allFlag=${allFlag}&generations="${generations}"`;
    //const url = `${this.peoplesUrl}`;
    //console.info("getPowerValues: URL = " + url);
    //return this.http.get<PowerEvent>(url);
    var ret = this.mockValue(i);
    return ret;
  }

  rangePick(mn, mx){
    var ret;
    ret =  Math.round(Math.random() * (mx - mn)) + mn;
    return ret;
  }

  mockValue(i): PowerEvent {
    var x = i % 4;

    var productionKWh;
    var consumptionKWh;
    var exportKWh;

    var ret = new PowerEvent();
    var solar;
    var cons;
    var grid;
    var exprt;
    var avoidCost;
    var feedIn;

    if (x == 0){
      // Early morning / night
      productionKWh = 0.0;
      consumptionKWh = this.rangePick(300, 1210);

      solar = 0;
      cons = this.rangePick(300, 1210);
      grid = cons;
      exprt = 0;
      avoidCost = 0;
      feedIn = 0;
    }
    else if (x == 1){
      // Mid morning
      productionKWh = this.rangePick(2000, 5000);
      consumptionKWh = this.rangePick(300, 2345);

      solar = this.rangePick(2000, 5000);
      cons = this.rangePick(300, 2345);
      // blah
      avoidCost = Math.round(Math.random() * 15.0) / 100.0;
      feedIn = Math.round(Math.random() * 30.0) / 100.0;
    }
    else if (x == 2){
      productionKWh = this.rangePick(2000, 11000);
      consumptionKWh = this.rangePick(300, 1234);

      // mid arvo
      solar = this.rangePick(2000, 11000);
      cons = this.rangePick(300, 1234);
      //grid = this.rangePick(350, 2745);
      avoidCost = 12.30;
      feedIn = 345.01;
    }
    else if (x == 3){
      // late  arvo
      productionKWh = this.rangePick(500, 1900);
      consumptionKWh = this.rangePick(300, 2345);

      solar = this.rangePick(500, 1900);
      cons = this.rangePick(300, 2345);

      grid = this.rangePick(312, 1999);
      avoidCost = 15.12;
      feedIn = 311.01;
    }

    if (productionKWh > consumptionKWh)
      exportKWh = productionKWh - consumptionKWh;
    else
      exportKWh = 0.0;
    console.info("export = [" + exportKWh.toString() + "]");


    if (cons > solar){
      grid = cons - solar;
      exprt = 0;
    }
    else{
      grid = 0;
      exprt = solar - exprt;
    }

    ret.productionKWh = productionKWh.toString();
    ret.consumptionKWh = consumptionKWh.toString();
    ret.exportKWh = exportKWh.toString();
    ret.peakPower = (Math.round(Math.random() * 2) % 2 == 1) ? "true" : "false";
    ret.dollarsAvoided = (this.rangePick(0, 200)/100).toFixed(2).toString();
    ret.dollarsCosting = (this.rangePick(0, 155)/100).toFixed(2).toString();
    ret.dollarsEarning = (this.rangePick(0, 223)/100).toFixed(2).toString();



    //ret.solar = solar.toString();
    //ret.grid = grid.toString();
    //ret.cons = cons.toString();
    //ret.exprt = exprt.toString();
    //ret.avoidCost = avoidCost.toString();
    //ret.feedIn = feedIn.toString();
    

    return ret;
  }

}
