import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

//import {TooltipPosition} from '@angular/material/tooltip';


import { PeoplesEvent } from "../peoples-event";
import { PeoplesService } from "../peoples.service";

const COOKIE_FILTER_NAME = "HOH_People_NameFilter";
const COOKIE_FILTER_SEX = "HOH_People_BirthSexFilter";
const COOKIE_FAV_PERSON = "HOH_People_FavPerson";


@Component({
  selector: 'app-people-list',
  templateUrl: './people-list.component.html',
  styleUrls: ['./people-list.component.css']
})
export class PeopleListComponent implements OnInit {

  id;
  filterName: string;
  filterBirthSex: string;
  sexOptions: string[] = ['All', 'Male', 'Female', 'Other', 'Not Recorded'];
  fetchingStatus;

  peoples: PeoplesEvent[];
  displayedColumns: string[] = ['id', 'firstName', 'familyName', 'dob', 'complete'];

  cookieFilterName: string;
  cookieFilterSex: string;

  itsMeId: string;

  constructor(private peoplesService: PeoplesService, private router: Router, private cookieService: CookieService) { }

  ngOnInit(): void {

    if (this.cookieService.check(COOKIE_FAV_PERSON)){
      console.info("loading fav id cookie");
      this.itsMeId = this.cookieService.get(COOKIE_FAV_PERSON);
    }

    if (this.cookieService.check(COOKIE_FILTER_NAME)){
      this.filterName = this.cookieService.get(COOKIE_FILTER_NAME);
      this.filterBirthSex = this.cookieService.get(COOKIE_FILTER_SEX);
    }
    else{
      // Default
      this.filterName = "Higgins";
      this.filterBirthSex = "All";
    }
    this.onFilter();
  }

  setData(peoplesList): void {
    var i: number;
    this.peoples = new Array;
    for (i = 0; i < peoplesList.length; i++) {
      this.peoples.push(peoplesList[i])
    }
    this.fetchingStatus = "Fetched " + this.peoples.length + " records";
  }

  // getPeoples(): void {
  //   this.peoplesService
  //     .getPeoples()
  //     .subscribe(peoplesEvents => (this.setData(peoplesEvents)));
  // }

  getPeoplesFiltered(name, sex): void {
    this.peoplesService
      .getPeoplesMatchingPeople(name, sex)
      .subscribe(peoplesEvents => (this.setData(peoplesEvents)));
  }

  gotoPeep(peepId) {
    console.info("PEEPID=[" + peepId + "]")
    this.router.navigate(['/peep', { id: peepId }]);  // define your component where you want to go
  }

  // getPeepStarsImg(peepId) {
    
  //   //var x ;
  //   var path;
  //   //x = Math.floor(Math.random() * 5);
  //   path = "./assets/images/stars-" + "2" + "_" + "3" + ".png";
  //   console.info(peepId + "-->" + path);
  //   return(path);
  // }

  // Return a Star if the attribute is defined. E
  // First name is star 0
  
  getPeepCompChk(peep, i) {
    //console.info("getPeepCompChk" + "-->" + i.toString() + " for peep " + peep.firstName + " where dq = " + peep.dqScore );
    if (parseInt(peep.dqScore) > i)
      return "star";
    else
      return "star_border";
    }

    fmtDateStr(strDt){
      var x: Date;
      
      if (strDt == 'Recorded' || strDt == 'Not Recorded' ) 
        return strDt;

      x = new Date(strDt);
      return x.toLocaleDateString()
    }

    OnFilterChange(){
      //console.info("FILTER CHANGE:[" + this.filterName + "]");
    }

    onFilter(){
      var fname;
      var sex;
      if (this.filterBirthSex == "All")
        sex = "";
      else
        sex = this.filterBirthSex;

      if (this.filterName == null)
        fname = "";
      else
        fname = this.filterName;

      //console.info("FILTER CLICKED [" + this.filterName + "] - sex = [" + sex + "]");
      this.fetchingStatus = "Fetching...";
      this.getPeoplesFiltered(fname, sex);

      // Save current values as cookies
      //var expiredDate = new Date();
      //var expiry = expiredDate.getDate() + 7 ;  // 1 week
      console.info("COOKIE for filter");    // + expiry.toString());
      this.cookieService.set(COOKIE_FILTER_NAME, this.filterName, 7); //,  "/x", "higgins.one", false, "Lax");
      this.cookieService.set(COOKIE_FILTER_SEX, this.filterBirthSex, 7);  //, "/x", "higgins.one", false, "Lax");

    }

    onNewPerson(){
      console.info("NEW PERSON");
      this.router.navigate(['/peep']);  // define your component where you want to go
    }

    onReset(){
      this.filterName = "";
      this.filterBirthSex = "All";
      this.onFilter();
    }

    itsMe(id){
      console.info("Clicked ID = " + id);
      this.itsMeId = id;
      this.cookieService.set(COOKIE_FAV_PERSON, this.itsMeId, 28); //,  "/x", "higgins.one", false, "Lax");
    }

    makeFavourite(id){
      if (id == this.itsMeId){
        //console.info("id for this is me - " + id);
        return 'favorite';
      }
      return 'favorite_border';
    }
    

}


// <label id="birth-sex-label">Registered Sex at birth</label>
// <mat-radio-group aria-labelledby="birth-sex-label" class="birthsex-radio-group" [(ngModel)]="filterBirthSex">
//     <mat-radio-button class="birthsex-radio-button" *ngFor="let sexOption of sexOptions" [value]="sexOption">
//         {{sexOption}}</mat-radio-button>
// </mat-radio-group>
