<div class="blob">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Solar</mat-card-title>
            <mat-card-subtitle>Some stuff</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-sm-image src="../assets/images/solar.png" alt="Power Logo">
        <mat-card-content>
            <mat-grid-list cols="4" rowHeight="300px">
                <mat-grid-tile>
                    <div class="powerBox">
                        <div *ngIf="powerEvt">
                            <div class="powerHdg">Solar Generation</div>
                            <div *ngIf="isGenerating()" class="powerAmtCR">{{getSolarGenerating()}}</div>
                            <div *ngIf="!isGenerating()" class="powerAmtDR">{{getSolarGenerating()}}</div>
                            <div class="powerDescCR">Using: {{getSolarSelfConsuming()}}</div>
                            <div class="powerDescCR">Exporting: {{getSolarExporting()}}</div>
                        </div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile>
                    <div class="powerBox">
                        <div *ngIf="powerEvt">
                            <div *ngIf="bPeakPower" class="powerHdg"><span matBadge="pk" matBadgePosition="after"
                                    matBadgeColor="primary">Consumption</span></div>
                            <div *ngIf="!bPeakPower" class="powerHdg"><span matBadge="op" matBadgePosition="after"
                                    matBadgeColor="accent">Consumption</span></div>
                            <div class="powerAmtDR">{{getPowerConsumption()}}</div>
                            <div class="powerDescCR">Solar: {{getPowerConsumptionSolarPerc()}}%</div>
                            <div class="powerDescDR">Grid: {{getPowerConsumptionGridPerc()}}%</div>
                        </div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile>
                    <div class="powerBox">
                        <div>
                            <div class="powerHdg">Dollars</div>
                            <div class="powerAmtCR">2.51 $h</div>
                            <div class="powerDescCR">Solar offset - {{dDollarsAvoided}} $h</div>
                            <div class="powerDescDR">Grid - {{dDollarsCosting}} $h</div>
                            <div class="powerDescCR">Feed-in - {{dDollarsEarning}} $h</div>
                        </div>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>
        <mat-card-footer>Updated Wed 08-07-2020 at 07:30:03 PM</mat-card-footer>
        <div *ngIf="debug">
            <button mat-button color="warn" (click)="debugClick()">Increment</button>
            <table *ngIf="powerEvt" style="font-size:x-large;">
                <tr>
                    <th>Production</th>
                    <th>Consumption</th>
                    <th>Export</th>
                    <th>Peak</th>
                    <th>Avoiding</th>
                    <th>Costing</th>
                    <th>Earning</th>
                </tr>
                <tr>
                    <td>{{powerEvt.productionKWh}}</td>
                    <td>{{powerEvt.consumptionKWh}}</td>
                    <td>{{powerEvt.exportKWh}}</td>
                    <td>{{bPeakPower}}</td>
                    <td>{{dDollarsAvoided}}</td>
                    <td>{{dDollarsCosting}}</td>
                    <td>{{dDollarsEarning}}</td>
                </tr>
            </table>
        </div>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Consumption Tracking</mat-card-title>
            <mat-card-subtitle>Blah Blah</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-sm-image src="../assets/images/solar.png" alt="Power Logo">
        <mat-card-content>
            <app-power-cons-chart></app-power-cons-chart>
        </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Rooms</mat-card-title>
            <mat-card-subtitle>More stuff</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-sm-image src="../assets/images/solar.png" alt="Power Logo">
        <mat-card-content>

            <mat-grid-list cols="3" rowHeight="1:1">
                <mat-grid-tile>
                    <div class="powerBox">
                        <div>
                            <div class="powerDesc">Blah</div>
                            <div class="powerAmt">xxx</div>
                        </div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile>
                    <div class="powerBox">
                        <div>
                            <div class="powerDesc">Blah2</div>
                            <div class="powerAmt">yyy</div>
                        </div>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>
        <mat-card-footer>Footer2</mat-card-footer>
    </mat-card>
</div>