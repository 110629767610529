import { Component, OnInit } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { BirthdayEvent } from "../birthday-event";
import { BirthdaysService } from "../birthdays.service";

const COOKIE_FILTER_SHOW_MEMORIALS= "HOH_People_ShowMemorials";
const COOKIE_FILTER_GENERATIONS = "HOH_People_Generations";
const COOKIE_FILTER_PREWEEKS = "HOH_People_PreWeeks";
const COOKIE_FILTER_POSTWEEKS = "HOH_People_PostWeeks";



@Component({
  selector: 'app-birthdays',
  templateUrl: './birthdays.component.html',
  styleUrls: ['./birthdays.component.css']
})
export class BirthdaysComponent implements OnInit {

  birthdayEvents: BirthdayEvent[];
  memorialEvents: BirthdayEvent[];
  preWeeks = 4;
  postWeeks = 4;
  showMemorial;   
  gen_M2;
  gen_M1;
  gen_P0;
  gen_P1;
  gen_P2;
  gen_P3;
  refreshEnabled = true;
  refreshComment = "";
  startTime;
  endTime;

  constructor(private birthdaysService: BirthdaysService, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.setFilterValues();
    this.getBirthdays();
  }

  setData(birthdayEvents):void {
    var i: number;
    this.birthdayEvents = new Array;
    this.memorialEvents = null;
    for (i=0; i < birthdayEvents.length; i++){
      if (birthdayEvents[i].living == "True")
        this.birthdayEvents.push(birthdayEvents[i])
      else{
        if (this.memorialEvents == null)
          this.memorialEvents = new Array;
        this.memorialEvents.push(birthdayEvents[i])
      }
    }
    this.refreshEnabled = true;
    const now = new Date()  
    this.endTime = Math.round(now.getTime());  // Milli seconds
    var duration;
    duration = (this.endTime - this.startTime) / 100.0 // in tenths of seconds
    duration = Math.round(duration)
    duration = duration / 10.0

    this.refreshComment = "Fetched " + birthdayEvents.length.toString() + " birthday" + (birthdayEvents.length == 1 ? "" : "s") + " in " + duration.toString() + " seconds";
    if (this.memorialEvents != null){
      // There were some birthday events for memorials
      this.showMemorial = true;
    }
    //this.birthdayEvents = birthdayEvents;
  }

  getBirthdays(): void {
    const now = new Date()  
    this.startTime = Math.round(now.getTime()) ;
    this.refreshEnabled = false;

    this.refreshComment = "Fetching birthdays..."
    this.birthdaysService
      .getBirthdays(this.preWeeks * 7, this.postWeeks * 7, this.showMemorial, this.getGenerationsString())
      .subscribe(birthdayEvents => (this.setData(birthdayEvents)));
  }


  getImageName(bd, living): String {
    if (living == "False")
      return "./assets/images/rip.png";

    if (bd < 0)
      return "./assets/images/cake99.png";
      //return "https://s3-ap-southeast-2.amazonaws.com/higgins.one/birthdays/assets/images/cake99.png";

    //return "https://s3-ap-southeast-2.amazonaws.com/higgins.one/birthdays/assets/images/cake" + bd.toString() + ".png";
    return "./assets/images/cake" + bd.toString() + ".png";
  }

  getBirthdayDescription(bEvent) {
    var retText: String;  
    if (bEvent.ageAtBirthday == 0){
      if (bEvent.daysAway < 0)
        retText =  `Big welcome to ${bEvent.name} ${(bEvent.daysAway * -1).toString()} days ago.`;
      else if (bEvent.daysAway == 0)
        retText =  `Big welcome to ${bEvent.name} today!`
      return retText;
    }

    if (bEvent.daysAway < 0)
      retText = `Happy birthday to ${bEvent.name} ${(bEvent.daysAway * -1).toString()} days ago.`;
    else if (bEvent.daysAway == 0)
      retText = `Happy birthday to ${bEvent.name} today!`
    else
      retText = `Happy birthday to ${bEvent.name} in ${bEvent.daysAway.toString()} days`
    return retText;
  }

  getMemorialDescription(bEvent) {
    if (bEvent.daysAway < 0)
      return `${(bEvent.daysAway * -1).toString()} days ago`;
    else if (bEvent.daysAway == 0)
      return `Today!`;
    else
      return `In ${bEvent.daysAway.toString()} days time`;
  }


  getGenerationsString() {
    var ret = "";
    if (this.gen_M2)
      ret += "-2,";
    if (this.gen_M1)
      ret += "-1,";
    if (this.gen_P0)
      ret += "0,";
    if (this.gen_P1)
      ret += "1,";
    if (this.gen_P2)
      ret += "2,";
    if (this.gen_P3)
      ret += "3";

    if (ret.endsWith(","))
      ret = ret.substr(0, ret.length - 1)

    return ret;
  }

  onClickRefresh() {
    console.log("Refresh button clicked!");
    this.getBirthdays();

    console.info("COOKIE (Birthdays)");    // + expiry.toString());
    this.cookieService.set(COOKIE_FILTER_GENERATIONS, this.encodeGenerationsCookie(), 7); //,  "/x", "higgins.one", false, "Lax");
    this.cookieService.set(COOKIE_FILTER_SHOW_MEMORIALS, this.showMemorial.toString(), 7);
    this.cookieService.set(COOKIE_FILTER_PREWEEKS, this.preWeeks.toString(), 7);
    this.cookieService.set(COOKIE_FILTER_POSTWEEKS, this.postWeeks.toString(), 7);

  }

  getRefreshEnabled() {
    return this.refreshEnabled;
  }

  onClickSelectAll(){
    this.gen_M2 = true;
    this.gen_M1 = true;
    this.gen_P0 = true;
    this.gen_P1 = true;
    this.gen_P2 = true;
    this.gen_P3 = true;
    this.preWeeks = 12;
    this.postWeeks = 12;
    this.showMemorial = true;  
    this.getBirthdays();
  }

  noBirthdays(){
    return(this.birthdayEvents.length == 0)
  }

  encodeGenerationsCookie(){
    var cookieValue = ''
    cookieValue  += this.gen_M2 ? '1' :'0';
    cookieValue  += this.gen_M1 ? '1' : '0';
    cookieValue  += '.';
    cookieValue  +=  this.gen_P0 ? '1' : '0';
    cookieValue  +=  this.gen_P1 ? '1' : '0';
    cookieValue  +=  this.gen_P2 ? '1' : '0';
    cookieValue  +=  this.gen_P3 ? '1' : '0';

    return cookieValue;
  }

  setFilterValues(){

    if (this.cookieService.check(COOKIE_FILTER_GENERATIONS)){
      var cookieValue = ''

      cookieValue = this.cookieService.get(COOKIE_FILTER_GENERATIONS);
      //this.filterBirthSex = this.cookieService.get(COOKIE_FILTER_SEX);
      this.gen_M2 = cookieValue[0] == '1';
      this.gen_M1 = cookieValue[1] == '1';
      this.gen_P0 = cookieValue[3] == '1';
      this.gen_P1 = cookieValue[4] == '1';
      this.gen_P2 = cookieValue[5] == '1';
      this.gen_P3 = cookieValue[6] == '1';
    }
    else{
      // Default
      this.gen_M2 = false;
      this.gen_M1 = false;
      this.gen_P0 = false;
      this.gen_P1 = false;
      this.gen_P2 = false;
      this.gen_P3 = true;
    }

    if (this.cookieService.check(COOKIE_FILTER_SHOW_MEMORIALS)){
      var cookieValue = ''
      cookieValue = this.cookieService.get(COOKIE_FILTER_SHOW_MEMORIALS);
      this.showMemorial = cookieValue == 'true';
    }
    else{
      this.showMemorial = false;
    }

    if (this.cookieService.check(COOKIE_FILTER_PREWEEKS)){
      var cookieValue = '';
      cookieValue = this.cookieService.get(COOKIE_FILTER_PREWEEKS);
      this.preWeeks = parseInt(cookieValue);
    }
    else{
      this.preWeeks = 4;
    }

    if (this.cookieService.check(COOKIE_FILTER_POSTWEEKS)){
      var cookieValue = '';
      cookieValue = this.cookieService.get(COOKIE_FILTER_POSTWEEKS);
      this.postWeeks = parseInt(cookieValue);
    }
    else{
      this.postWeeks = 4;
    }
  }

}

//<button mat-fab color="primary">Refresh</button>
//<button mat-button color="primary">Refresh</button>
//<mat-divider [vertical]="true"></mat-divider>

//<br>
//<p>preWeeks = {{preWeeks}} || postWeeks = {{postWeeks}} || showMemorial = {{showMemorial}} || genations =
//  {{getGenerationsString()}}</p>
//<br>

// <section class="example-section"> 
// 				<ng-container *ngIf="getRefreshEnabled()">
// 				 	<button class="refreshButton" mat-raised-button color="primary" (click)="onClickRefresh()" >Refresh</button>
// 				</ng-container>
// 				<ng-container *ngIf="!getRefreshEnabled()">
// 					<button class="refreshButton" mat-raised-button disabled>Refresh</button>
// 				</ng-container>
// 				<div>{{refreshComment}}</div>
// 			</section>