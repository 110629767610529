import { Component, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChange } from '@angular/core';


import { PeoplesEvent } from "../peoples-event";
import { PeoplesService } from "../peoples.service";

const defaultDOB = "2000-01-01";

@Component({
  selector: 'app-peoples-base-details',
  templateUrl: './peoples-base-details.component.html',
  styleUrls: ['./peoples-base-details.component.css']
})
export class PeoplesBaseDetailsComponent implements OnInit, OnChanges {

  @Input('person') person: PeoplesEvent;
  @Output() peepUpdateEvent = new EventEmitter<any>();
  //@Output('person') person: PeoplesEvent;

  debug = false;
  loadedPerson;   // Keep a copy of person
  sexOptions: string[] = ['Male', 'Female', 'Other', 'Not Recorded'];
  bLiving: boolean  = true;

  firstNameChgCount = 0;
  firstNameChgCountModel = 0;

  constructor() { }

  ngOnInit(): void {
    //console.info("ngOnInit [BASE] called - person.id=[" + this.person.id + "]");
    //console.info("ngOnInit [BASE] called - person.living=[" + this.person.living + " {type " + typeof this.person.living + "}]");
    this.bLiving = this.person.living == "True";
    this.loadedPerson = JSON.parse(JSON.stringify(this.person));  // copy of person
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    //var newVal;

    //console.info("ngOnChanges[basedets]..##################.");
    //this.peepUpdateEvent.emit(this.person);

    // for (let propName in changes) {
    //   let changedProp = changes[propName];
    //   newVal = changedProp.currentValue;

    //   if (!changedProp.isFirstChange()) {
    //     //console.info("  &&&& newVal = <" + newVal + ">")
    //     if (propName == "reliIdStr") {
    //       this.setReliValue(newVal);
    //     }
    //   }
    // }
  }

  getCardTitle(){
    if (this.person.id == ''){
      return "New Person";
    }
    else{
      if (this.person == null){
        return "Pending...";
      }
      else {
        var ret = this.person.firstName;
        if (this.person.preferredName != "")
          ret += " (" + this.person.preferredName + ")";
        ret += " " + this.person.familyName;
        return ret;
      }
    }
  }

  OnFieldChange(){
    //this.firstNameChgCount++;
    console.info("BASE onFieldChange");
    this.peepUpdateEvent.emit(this.person);
  }

  getFirstNameDisabled() {
    var ret: string;
    ret = "true";
    if (this.loadedPerson != null) {
      if (this.loadedPerson.firstName == "")
        ret = "false";
      else
        ret = "true";
    }
 
    return ret;
  }

  getFamilyNameDisabled() {
    var ret: string;
    ret = "true";
    if (this.loadedPerson != null) {
      if (this.loadedPerson.familyName == "")
        ret = "false";
      else
        ret = "true";
    }
    //console.info("getFamilyNameDisabled = " + ret);
    return ret;
  }

  getPreferredNameDisabled() {
    var ret: string;
    ret = "true";
    if (this.loadedPerson != null) {
      //console.info("loadPerrson NOT NULL")
      if (this.loadedPerson.preferredName == ""){
        //console.info("loadPerrson preferredName == 0")
        ret = "false";
      }
      else{
        //console.info("loadPerrson preferredName != 0 .. its " + this.loadedPerson.preferredName);
        ret = "true";
      }
    }
    //console.info("getPreferredNameDisabled = " + ret);
    return ret;
  }

  getOtherNamesDisabled() {
    var ret: string;
    ret = "true";
    if (this.loadedPerson != null) {
      //console.info("loadPerrson NOT NULL")
      if (this.loadedPerson.otherNames == "" || this.loadedPerson.otherNames == null){
        //console.info("loadPerson otherNames == ''");
        ret = "false";
      }
      else{
        //console.info("loadPerson otherNames != '' ... its " + this.loadedPerson.otherNames);
        ret = "true";
      }
    }
    //console.info("getPreferredNameDisabled = " + ret);
    return ret;
  }


  isDobDefined(): boolean{
    var ret = false;

    if (this.person.dob == "defined")
      ret = true;
    else if (this.person.dob != defaultDOB)
      ret = true;

    return ret;
  }

  OnChangeLiving(){
    console.info("Change Living to " + this.bLiving);
    if (this.bLiving)
      this.person.living = "True";
    else
      this.person.living = "False";
    this.OnFieldChange();
  }
}


// <label id="birth-sex-label">Registered Sex at birth</label>
//         <mat-radio-group aria-labelledby="birth-sex-label" class="birthsex-radio-group" [(ngModel)]="person.birthSex">
//             <mat-radio-button class="birthsex-radio-button" *ngFor="let sexOption of sexOptions" [value]="sexOption">
//                 {{sexOption}}</mat-radio-button>
//         </mat-radio-group>