<div>

    <mat-card>
        <h1>OOPS - page not found</h1>

        <mat-card-content>
            <mat-card-title>Possibilities</mat-card-title>
            <br>
            <p>{{path}}</p>
            <br>
            <p>{{rawPath}}</p>
            <br>
            
            <a href="https://bobrov.dev/blog/angular-smart-404-page/">looks intersting for this page</a>

            
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="primary" (click)="onList()">
                  <mat-icon>group</mat-icon>People List
                </button>
                <button mat-raised-button color="cake" (click)="onBirthday()">
                  <mat-icon>cake</mat-icon>Birthday List
                </button>
              </mat-card-actions>
        </mat-card-content>
    </mat-card>
</div>