import { Component, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


import { PeoplesEvent } from "../peoples-event";
import { PeoplesService } from "../peoples.service";
import { strict } from 'assert';

const defaultDOB = "2000-01-01";

//export interface DialogData {
//  animal: string;
//  name: string;
//}

@Component({
  selector: 'app-reli-detail',
  templateUrl: './reli-detail.component.html',
  styleUrls: ['./reli-detail.component.css']
})
export class ReliDetailComponent implements OnInit {

  @Input('person') person: PeoplesEvent;
  @Input('reliType') reliTypeStr: string;
  @Input('reliId') reliId: string;
  @Input('reli') reli: PeoplesEvent;
  //@Output('reli') reli: PeoplesEvent;
  @Output() reliUpdateEvent = new EventEmitter<any>();

  //reli: PeoplesEvent;
  debug = false;

  bAddNew = false;
  bRecorded: boolean;
  reliOption = "Recorded";  // Button Toggle button option
  searchString: string;
  selectedValue: string;

  value;
  selValue;
  possibleList;
  searchResults;    // Keep a copy so I can find the guid

  constructor(private peoplesService: PeoplesService, private route: ActivatedRoute, private router: Router /*public dialog: MatDialog*/) { }

  ngOnInit(): void {
    this.searchString = "";
    //console.info("ngOnInit [RELI] reliId = [" + this.reliId + "]");
    if (this.reliId == "Recorded") {
      this.bRecorded = true;
      this.value = this.reliId;
    }
    else if (this.reliId == "" || this.reliId == "Not Recorded") {
      this.bRecorded = false;
      this.reli = this.setDefaultPerson();
      this.reliOption = "Search";
      this.value = "";
    }
    else {
      this.getPerson(this.reliId);
      this.bRecorded = true;
    }
    //console.info("ngOnInit [RELI] bRecord = [" + this.bRecorded + "]");
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    var newVal;

    //console.info("ngOnChanges[relidets]...");
    // for (let propName in changes) {
    //   let changedProp = changes[propName];
    //   newVal = changedProp.currentValue;

    //   if (!changedProp.isFirstChange()) {
    //     //console.info("  &&&& newVal = <" + newVal + ">")
    //     if (propName == "reliIdStr") {
    //       this.setReliValue(newVal);
    //     }
    //   }
    // }
  }

  // setReliValue(newVal) {
  //   if (newVal == "Recorded") {
  //     this.value = "Recorded";
  //     this.bRecorded = true;
  //   }
  //   else if (newVal == "Not Recorded")
  //     this.value = "";
  //   else {
  //     //this.value = "Kippers looksup(" + newVal + ")";
  //     this.value = ""
  //     this.getPerson(newVal);
  //     this.bRecorded = true;
  //   }
  // }

  getDisabled(bTarget) {
    if (this.bRecorded == bTarget)
      return "true";
    else
      return "false";
  }

  getPerson(id): void {
    this.peoplesService
      .getPerson(id)
      .subscribe(peoplesEvents => (this.setData(peoplesEvents)));
  }

  setData(peoplesList): void {
    var dispName: string;
    var person: PeoplesEvent;
    //console.info("SetData [RELIDETAIL]")

    if (peoplesList != null) {
      if (peoplesList.length > 0) {
        person = peoplesList[0];
        dispName = person.firstName;
        if (person.preferredName != "")
          dispName += " (" + person.preferredName + ")"
        dispName += " " + person.familyName;
        this.value = dispName;
        //console.info("SetData [RELI]--> Displayname = " + dispName);
        this.bRecorded = true;
        //this.reli = person
        //@OUTPUT this.reli.firstName = person.firstName;
        //@OUTPUT this.reli.familyName = person.familyName;
      }
    }
  }

  setDefaultPerson(){
    //console.info("setDefaultPerson [reli-detail]");
    var person = new PeoplesEvent;
    person.id = "";
    person.firstName = "";
    person.familyName = "";
    person.otherNames = "";
    person.maidenName = "";
    person.preferredName = "";
    person.birthSex = this.reliTypeStr == "Mother" ? "Female" : "Male";
    person.dob = defaultDOB;
    person.dod = "";
    person.living = "True";
    person.motherid = "Not Recorded";
    person.fatherid = "Not Recorded";
    return person;
  }

  setSearchResData(peoplesList){
    var dispName: string;
    var person: PeoplesEvent;

    this.possibleList = new Array();
    this.searchResults = peoplesList;   // Save a copy

    if (peoplesList != null) {
      if (peoplesList.length > 0) {
        for (let index = 0; index < peoplesList.length; index++) {
          person = peoplesList[index]
          dispName = person.firstName;
          if (person.preferredName != "")
            dispName += " (" + person.preferredName + ")"
          dispName += " " + person.familyName;
          //console.info("SetData for query runs --> Displayname = " + dispName);
          this.possibleList.push(dispName);
        }
      }
    }
  }

  onClickReliSearch(){
    var birthSex;
    var famName;

    console.info("onClickReliSearch");  // + url);

    if (this.reliTypeStr == "Mother")
      birthSex = "Female";
    else  
      birthSex = "Male";
    
    famName = this.searchString;   // use value typed in.

    this.peoplesService
      .getPeoplesMatchingPeople(famName, birthSex)
      .subscribe(peoplesEvents => (this.setSearchResData(peoplesEvents)));
  }

  onClickReliSelected(){
    var nam;
    var idx: number;
    console.info("onClickReliSelected!!!"); 

    for(idx = 0; idx < this.possibleList.length; idx++){
      console.info("onClickReliSelected -- reli Comparing '" + this.possibleList[idx] + "' to '" + this.value + "'");
      if (this.possibleList[idx] == this.value){
        console.info("Matched where IDX = " + idx.toString());
        break;
      }
    }
    console.info("onClickReliSelected -- id of selected = " + this.searchResults[idx].id);
    this.reli = this.searchResults[idx];
    console.info("onClickReliSelected -- reli now = " + this.reli.id);
    this.reliUpdateEvent.emit(this.reli);
  }

  // motherUpdateEvent(event){
  //   console.info("reli-details->motherUpdateEvent - event = " + event);
  //   console.info("reli-details->motherUpdateEvent - event = " + event.firstName);
    
  //   //this.reliUpdateEvent.emit(event);
  //   //this.motherUpdateEvent.
  // }
  // fatherUpdateEvent(event){
  //   console.info("reli-details->fatherUpdateEvent - event = " + event);
  //   console.info("reli-details->fatherUpdateEvent - event = " + event.firstName);
  // }

  peepUpdateEvent(event){
    console.info("reli-details->peepUpdateEvent - event = " + event + " fn=" + event.firstName);
    //his.person.firstName = event.firstName;
    this.reliUpdateEvent.emit(event);
  }
  
}

// <div>type={{reliTypeStr}} relid={{reliIdStr}} childid={{childIdStr}}</div>

//window.open('./peep?linkId=blah:likedIdType=blah2')

//<button mat-button *ngIf="!bRecorded" matSuffix mat-icon-button aria-label="add" (click)="onClickReliAdd()">
//<mat-icon>person_add</mat-icon>
//</button>

// <a mat-button matSuffix [routerLink]="['/peep']" [queryParams]="{linkedId:'blah', linkedIdType:'blah2'}" target="_self">
//             <mat-icon>person_add</mat-icon>
//         </a>


// <button mat-button *ngIf="!bRecorded" matSuffix mat-icon-button aria-label="add" (click)="onClickReliAdd()">
// <mat-icon>person_add</mat-icon>
// </button>

// &nbsp;
// <button mat-button *ngIf="!bRecorded"  matSuffix  aria-label="search" (click)="onClickReliSearch()">Search
//     <mat-icon>search</mat-icon>
// </button>
// <button mat-button *ngIf="!bRecorded"  matSuffix  aria-label="add" (click)="onClickReliAdd()">Add new
//     <mat-icon>person_add</mat-icon>
// </button>
// &nbsp;

//                <mat-radio-button value="Recorded">{{reliTypeStr}} recorded</mat-radio-button>

// <div *ngIf="!bRecorded">
// <br>
// <mat-radio-group aria-label="Select an option"  [(ngModel)]="reliOption">
//     <mat-radio-button value="AddNew">Add new</mat-radio-button>
//     <mat-radio-button value="Search">Search existing</mat-radio-button>
// </mat-radio-group>
// </div>