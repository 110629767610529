
const MAINPEEP = 0;
const MOTHER = 1;
const FATHER = 2;
// STEP PARENTS TO COME.
const FAMLEN: number = 3; // To hold each of the above

export class PeoplesEvent {
    getDefaultDOB(){return("2000-01-01");};
    merge(obj){
      this.id = obj.id.toString();
      this.firstName = obj.firstName.toString();
      this.familyName = obj.familyName.toString();
      this.otherNames = obj.otherNames.toString();
      this.maidenName = obj.maidenName.toString();
      this.preferredName = obj.preferredName.toString();
      this.birthSex = obj.birthSex.toString();;
      this.dob = obj.dob.toString();
      this.dod = obj.dod.toString();
      //this.notes = obj.notes.toString();
      //this.living = obj.living.toString();
    };

    id: string;
    version: string;
    firstName: string;
    familyName: string;
    otherNames: string;
    maidenName: string;
    preferredName: string;
    birthSex: string;
    dob: string;
    dod: string;
    living: string;
    dqScore: number;
    motherid: string;
    fatherid: string;
    notes: string;
    // 
  };
  
  //export class PeopleFamily {
    // The main person and the parents associated with them
  //  persons: PeoplesEvent[];
  //}
   
  