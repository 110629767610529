<table *ngIf="(person)&&(debug==true)">
    <tr>
        <th>Person</th>
        <th>Reli {{reliTypeStr}}</th>
    </tr>
    <tr>
        <td>
            id = {{person.id}}<br>
            FirstName = {{person.firstName}}<br>
            FamilyName = {{person.familyName}}<br>
            PreferredName = {{person.preferredName}}<br>
            MaidenName = {{person.maidenName}}<br>
            Sex = {{person.birthSex}}<br>
            dob = {{person.dob}} dod={{person.dod}} -- Living = {{person.living}}<br>
            motherid = {{person.motherid}}<br> 
            fatherid = {{person.fatherid}}<br>
        </td>
        <td>
            id = {{reli.id}}<br>
            FirstName = {{reli.firstName}}<br>
            FamilyName = {{reli.familyName}}<br>
            PreferredName = {{reli.preferredName}}<br>
            MaidenName = {{reli.maidenName}}<br>
            Sex = {{reli.birthSex}}<br>
            dob = {{reli.dob}} dod={{reli.dod}} -- Living = {{reli.living}}<br>
            motherid = {{reli.motherid}}<br> 
            fatherid = {{reli.fatherid}}<br>
</td>
    </tr>
</table>
<mat-card *ngIf="person">
    <mat-card-content>
        <mat-card-title>{{reliTypeStr}} of {{person.firstName}}</mat-card-title>
        <div *ngIf="reliTypeStr">
            <mat-form-field appearance="fill" style="width:30vw">
                <mat-label>{{reliTypeStr}}</mat-label>
                <input matInput type="text" [(ngModel)]="value" disabled="True" value="{{value}}">
                <mat-icon *ngIf="!bRecorded" matPrefix>star_border</mat-icon>
                <mat-icon *ngIf="bRecorded" matPrefix>star</mat-icon>
                <mat-hint *ngIf="!bRecorded">{{reliTypeStr}} of {{person.firstName}}.</mat-hint>
            </mat-form-field>
        </div>
        <div *ngIf="bRecorded==false">        
            <mat-button-toggle-group name="fontStyle" aria-label="Select an option" [(ngModel)]="reliOption">
                <mat-button-toggle value="Recorded"  disabled={{getDisabled(false)}}>Recorded </mat-button-toggle>
                <mat-button-toggle value="AddNew"  disabled={{getDisabled(true)}}>Add new</mat-button-toggle>
                <mat-button-toggle value="Search" disabled={{getDisabled(true)}}>Search existing</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </mat-card-content>
    <div *ngIf="reliOption=='Search'" >
        <mat-form-field appearance="fill" style="width:220px">
            <mat-label>Search String</mat-label>
            <input matInput type="text" [(ngModel)]="searchString" value="{{searchString}">
            <mat-hint>Enter family name to search.</mat-hint>
            <button *ngIf="this.searchString!=''" mat-button matSuffix aria-label="search"  (click)="onClickReliSearch()">
                <mat-icon>search</mat-icon>
            </button>
            <button *ngIf="this.searchString==''" mat-button matSuffix aria-label="search" disabled (click)="onClickReliSearch()">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
        &nbsp;
        <mat-form-field *ngIf="possibleList" style="width:220px">
            <mat-label>Select person</mat-label>
            <mat-select [(value)]="value" (selectionChange)="onClickReliSelected()">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let poss of possibleList" value="{{poss}}">{{poss}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="reliOption=='AddNew'">
        <app-peoples-base-details [person]='reli' (peepUpdateEvent)="peepUpdateEvent($event)"></app-peoples-base-details>
    </div>
</mat-card>