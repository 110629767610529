<div *ngIf="birthdayEvents" class="blob">

	<mat-card class="result-card">
		<mat-card-content>
			<mat-card-title>Birthdays</mat-card-title>
			<table style="width:100%">
				<tr *ngFor="let bd of birthdayEvents">
					<td><img src="{{getImageName(bd.ageAtBirthday, bd.living)}}" alt="birthday cake"
							style="width:150px;height:150px;"></td>
					<td class="living">{{getBirthdayDescription(bd)}}
						<p *ngIf="bd.ageAtBirthday < 17 && bd.ageAtBirthday > 1"><b>{{bd.ageAtBirthday}}</b> years old!
						</p>
						<p *ngIf="bd.ageAtBirthday == 1"><b>1</b> year old!</p>
					</td>
				</tr>
			</table>
			<div *ngIf="noBirthdays()">
				<b>No birthdays to show!</b>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card class="result-card" *ngIf="showMemorial">
		<mat-card-content>
			<mat-card-title>Memorials</mat-card-title>
			<div *ngIf="memorialEvents">
				<table style="width:100%">
					<tr *ngFor="let bd of memorialEvents">
						<td class="memorialAge">{{bd.ageAtBirthday}}<br>
							<p class="dateRelative">{{getMemorialDescription(bd)}}</p>
						</td>
						<td class="dead">{{bd.name}}<br>
							<p class="dateRange">{{bd.dobFmt}} &mdash; {{bd.dodFmt}}</p>
						</td>
					</tr>
				</table>
			</div>
			<div *ngIf="!memorialEvents">
				<b>No memorials to show!</b>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card class="result-card">
		<mat-card-content>
			<mat-card-title>Selectors</mat-card-title>
			<section class="section">
				<button mat-raised-button color="accent" (click)="onClickSelectAll()">Select All</button>
			</section>
			<section class="section">
				<h3>Weeks until birthday ({{preWeeks}})</h3>
				<mat-slider thumbLabel min="1" max="12" step="1" [(ngModel)]="preWeeks"></mat-slider>
				<h3>Weeks since birthday ({{postWeeks}})</h3>
				<mat-slider thumbLabel min="1" max="12" step="1" [(ngModel)]="postWeeks"></mat-slider>
			</section>

			<section class="section">
				<mat-checkbox class="example-margin" [(ngModel)]="showMemorial">Show memorials</mat-checkbox>
			</section>

			<section class="section">
				<h3>Generations:&nbsp;</h3>
				<mat-checkbox class="example-margin" [(ngModel)]="gen_M2">Older</mat-checkbox>
				<mat-checkbox class="example-margin" [(ngModel)]="gen_M1">Grace</mat-checkbox>
				<mat-checkbox class="example-margin" [(ngModel)]="gen_P0">Nita</mat-checkbox>
				<mat-checkbox class="example-margin" [(ngModel)]="gen_P1">Jo</mat-checkbox>
				<mat-checkbox class="example-margin" [(ngModel)]="gen_P2">Kaitlyn</mat-checkbox>
				<mat-checkbox class="example-margin" [(ngModel)]="gen_P3">Ava</mat-checkbox>
			</section>

			<section class="section">
				<ng-container *ngIf="getRefreshEnabled()">
					<button mat-raised-button color="primary" (click)="onClickRefresh()">Refresh</button>
				</ng-container>
				<ng-container *ngIf="!getRefreshEnabled()">
					<button mat-raised-button disabled>Refresh</button>
				</ng-container>
			</section>
		</mat-card-content>
		<mat-card-footer>{{refreshComment}}</mat-card-footer>
	</mat-card>

</div>