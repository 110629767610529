import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule }    from '@angular/common/http';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BirthdaysService } from './birthdays.service';

import { CookieService } from 'ngx-cookie-service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox'; 
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { MatInputModule } from '@angular/material/input'; 
import { MatSelectModule } from '@angular/material/select'; 
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list'; 
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table'; 
import { MatRadioModule } from '@angular/material/radio'; 
import { MatExpansionModule } from '@angular/material/expansion'; 
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs'; 
import { MatBadgeModule } from '@angular/material/badge'; 
import { MatTooltipModule } from '@angular/material/tooltip';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { ChartsModule } from 'ng2-charts';

import { PeopleListComponent } from './people-list/people-list.component';
import { PeoplesDetailComponent } from './peoples-detail/peoples-detail.component';
import { ReliDetailComponent } from './reli-detail/reli-detail.component';
import { PeoplesBaseDetailsComponent } from './peoples-base-details/peoples-base-details.component';
import { BirthdaysComponent } from './birthdays/birthdays.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import { PowerComponent } from './power/power.component';
import { PowerConsChartComponent } from './power-cons-chart/power-cons-chart.component';


@NgModule({
  declarations: [
    AppComponent,
    PeopleListComponent,
    PeoplesDetailComponent,
    ReliDetailComponent,
    PeoplesBaseDetailsComponent,
    BirthdaysComponent,
    PageNotFoundComponent,
    HomeComponent,
    PowerComponent,
    PowerConsChartComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    MatNativeDateModule,
    MatButtonModule, MatCardModule, MatDividerModule, MatCheckboxModule, 
    MatSliderModule, MatFormFieldModule, MatInputModule, MatSelectModule,
    MatIconModule, MatListModule, MatGridListModule, MatTableModule, MatSortModule,
    MatRadioModule, MatExpansionModule, MatButtonToggleModule, MatTabsModule, MatBadgeModule,
    MatTooltipModule,
    ChartsModule
  ],
  providers: [
    BirthdaysService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
