import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { PeopleListComponent } from './people-list/people-list.component';
import { PeoplesDetailComponent } from './peoples-detail/peoples-detail.component';
import { BirthdaysComponent } from './birthdays/birthdays.component';
import { PowerComponent } from './power/power.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  //{ path: '', redirectTo: '/list', pathMatch: 'full' },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'list', component: PeopleListComponent },
  { path: 'home', component: HomeComponent },
  { path: 'peep:id', component: PeoplesDetailComponent, runGuardsAndResolvers: 'always' },
  { path: 'peep', component: PeoplesDetailComponent, runGuardsAndResolvers: 'always' },
  { path: 'birthdays', component: BirthdaysComponent },
  { path: 'power', component: PowerComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
