import { Component, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { PeoplesEvent } from "../peoples-event";
import { PeoplesService } from "../peoples.service";

//import {DomSanitizer} from '@angular/platform-browser';
//import {MatIconRegistry} from '@angular/material/icon';

const defaultDOB = "2000-01-01";

@Component({
  selector: 'app-peoples-detail',
  templateUrl: './peoples-detail.component.html',
  styleUrls: ['./peoples-detail.component.css']
})
export class PeoplesDetailComponent implements OnInit {

  debug = false;
  person: PeoplesEvent;
  motherConst = 'Mother'
  mother;
  fatherConst = 'Father'
  father;

  isNew = "unknown";
  id;
  linkedId;       // When linked from another person form
  linkedIdType;   // I.e. The mother/father of the other person

  savingStatus;
  
  //loadedPerson;   // Keep a copy of person

  constructor(private peoplesService: PeoplesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    //console.info("ngOnInit called");
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id'); 
      //console.info("ngOnInit-->id = [" + this.id + "]");
      if (this.id == null || this.id == ''){
        this.isNew = "true";
        this.person = this.setDefaultPerson();
        this.mother = this.setDefaultPerson();
        this.father = this.setDefaultPerson();
        //this.loadedPerson = JSON.parse(JSON.stringify(this.person));  // copy of default
      }
      else{
        this.isNew = "false";
        this.getPerson(this.id);
      }
      //console.info("New Person = " + this.isNew);
      //this.linkedId = params.get('linkedId')
      //this.linkedIdType = params.get('linkedIdType')
      //console.info("linkedId = " + this.linkedId);
    });
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    var newVal;

    //console.info("ngOnChanges[peepdets]...");
    for (let propName in changes) {
      let changedProp = changes[propName];
      newVal = changedProp.currentValue;

      if (!changedProp.isFirstChange()) {
        //console.info("gnOnChanges - isFirstChange")
        //console.info("  &&&& newVal = <" + newVal + ">")
        //if (propName == "reliIdStr") {
          //this.setReliValue(newVal);
        //}
      }
    }
  }

  setData(peoplesList): void {
    //var i: number;
    //console.info("SetData ...");
    if (peoplesList != null){
      if (peoplesList.length > 0){
        //console.info("Peoples-detail - SetData");
        this.person = peoplesList[0];

        if (this.person.dob == "Not Recorded")
          this.person.dob = defaultDOB;

        if (this.person.maidenName == "Not Recorded")
          this.person.maidenName = "";

        if (this.person.otherNames == "Not Recorded")
          this.person.otherNames = "";

        //console.info("setData --- living (before)= " + this.person.living + " type = " + typeof(this.person.living));
           
        //console.info("setData --- living (afterx)= " + this.person.living + " type = " + typeof(this.person.living));
        //console.info("motherid = " + this.person.motherid)
        
        this.mother = new PeoplesEvent; 
        this.mother.id = this.person.motherid;
        this.father = new PeoplesEvent;
        this.father.id = this.person.fatherid;
      }
    }
  }

  setDefaultPerson() : PeoplesEvent{
    var person;
    person = new PeoplesEvent;
    person.id = "";
    person.version = "0";
    person.firstName = "";
    person.familyName = "";
    person.otherNames = "";
    person.maidenName = "";
    person.preferredName = "";
    person.birthSex = "Not Recorded";
    person.dob = defaultDOB;
    person.dod = "";
    person.living = true;
    person.motherid = "Not Recorded";
    person.fatherid = "Not Recorded";
    return person;
  }

  getPerson(id): void {
    this.peoplesService
      .getPerson(id)
      .subscribe(peoplesEvents => (this.setData(peoplesEvents)));
  }
  
  isDobDefined(): boolean {
    var ret = false;

    if (this.person.dob == "defined")
      ret = true;
    else if (this.person.dob != defaultDOB)
      ret = true;

    return ret;
  }

  motherDefined(): boolean{
    if (this.person.motherid == "Not Recorded")
      return false;
    else
      return true;
  }

  fatherDefined(): boolean{
    if (this.person.fatherid == "Not Recorded")
      return false;
    else
      return true;
  }

  peepUpdateEvent(event){
    //console.info("details->peepUpdateEvent - event = " + event + " fn=" + event.firstName);
    //this.person.merge(event);
    this.person.firstName = event.firstName;
    this.person.familyName = event.familyName;
    this.person.otherNames = event.otherNames;
    this.person.maidenName = event.maidenName;
    this.person.preferredName = event.preferredName;
    this.person.birthSex = event.birthSex;
    this.person.dob = event.dob;
    this.person.dod = event.dod;
    this.person.living = event.living;
  }

  motherUpdateEvent(event){
    //
    //console.info("peep-details->motherUpdateEvent - event = " + event);
    //console.info("peep-details->motherUpdateEvent - event = " + event.firstName);
    this.mother.merge(event);
    this.person.motherid = this.mother.id;
    //this.mother.firstName = event.firstName;
  }
  fatherUpdateEvent(event){
    //
    //console.info("reli-details->fatherUpdateEvent - event = " + event);
    //console.info("reli-details->fatherUpdateEvent - event = " + event.firstName);
    this.father.merge(event);
    this.person.fatherid = this.father.id;
    //this.father.firstName = event.firstName;
  }

  // Save functions....

  setPersonsResponseData(peoplesPutResult){
    console.info("Saving person -- response -->" + peoplesPutResult.result + " id = [" + peoplesPutResult.id + "]")
    if (peoplesPutResult.result == 'Success'){ 
      this.savingStatus = "Person record saved";
      this.person.id =  peoplesPutResult.id;
    }
    //else ToDo  
  }

  //peoplesPutResult
  savePerson(person): void {
    console.info("======SAVE PERSON!=====");
    this.savingStatus = "Saving person record...";
    this.peoplesService
      .putPerson(person)
      .subscribe(peoplesPutResult => (this.setPersonsResponseData(peoplesPutResult)));
  }

  setMotherResponseData(peoplesPutResult){
    console.info("Saving mother -- response -->" + peoplesPutResult.result + " id = [" + peoplesPutResult.id + "]")
    if (peoplesPutResult.result == 'Success'){
      this.savingStatus = "Mother record saved";
      this.mother.id =  peoplesPutResult.id;
      this.person.motherid = this.mother.id; 
    }
    //else ToDo  
    this.savePerson(this.person);
  }

  saveMother():void {
    //console.info("==SAVE MOTHER!==" + typeof(this.mother.id));
    if ((this.mother.id == "") && (this.mother.firstName != "" || this.mother.familyName != "")){
      this.savingStatus = "Saving mother record...";
      this.peoplesService
          .putPerson(this.mother)
          .subscribe(peoplesPutResult => (this.setMotherResponseData(peoplesPutResult)));
    }
    else{
      // Nothing to save, just do the peep
      this.savePerson(this.person);
    }
  }

  setFatherResponseData(peoplesPutResult){
    console.info("Saving mother -- response -->" + peoplesPutResult.result + " id = [" + peoplesPutResult.id + "]")
    if (peoplesPutResult.result == 'Success'){
      this.savingStatus = "Father record saved!";
      this.father.id =  peoplesPutResult.id;
      this.person.fatherid = this.father.id; 
    }

    //else ToDo  
    this.saveMother();
  }


  saveFather():void {
    //console.info("==SAVE FATHER!== " + typeof(this.father.id) );
    if ((this.father.id == "") && (this.father.firstName != "" || this.father.familyName != "")) {
      this.savingStatus = "Saving father record...";
      this.peoplesService
          .putPerson(this.father)
          .subscribe(peoplesPutResult => (this.setFatherResponseData(peoplesPutResult)));
    }
    else{
      // Nothing to save, try the mother
      this.saveMother();
    }
  }
  
  onClickSave(){
    console.info("======SAVE CLICKED!=====");
    // Try each peep in turn, start with the father
    this.saveFather();  
  }

  onClickCancel(){
    console.info("======CANCEL CLICKED!=====");
    this.ngOnInit();
  }

  gotoList() {
    console.info("GoBack to List")
    this.router.navigate(['/list']);  // define your component where you want to go
  }

}


// <mat-card>
// <mat-card-content>
//     <mat-card-title *ngIf="isNew=='true'">Person (New)</mat-card-title>
//     <mat-card-title *ngIf="isNew!='true'">Person</mat-card-title>

//     <mat-form-field *ngIf="person" appearance="fill" style="width:25vw">
//         <mat-label>First name</mat-label>
//         <input matInput placeholder="Barry" [(ngModel)]="person.firstName"
//             disabled="{{getFirstNameDisabled()}}">
//         <mat-icon *ngIf="person.firstName==''" matPrefix>star_border</mat-icon>
//         <mat-icon *ngIf="person.firstName!=''" matPrefix>star</mat-icon>
//         <mat-hint>First or common name for the person</mat-hint>
//     </mat-form-field>
//     &nbsp;
//     <mat-form-field *ngIf="person" appearance="fill" style="width:25vw">
//         <mat-label>Family name</mat-label>
//         <input matInput placeholder="McDuck" [(ngModel)]="person.familyName"
//             disabled="{{getFamilyNameDisabled()}}">
//         <mat-icon *ngIf="person.familyName==''" matPrefix>star_border</mat-icon>
//         <mat-icon *ngIf="person.familyName!=''" matPrefix>star</mat-icon>
//         <mat-hint>Family or surname for the person</mat-hint>
//     </mat-form-field>
//     &nbsp;
//     <mat-form-field appearance="fill" style="width:25vw">
//         <mat-label>Preferred Name</mat-label>
//         <input matInput placeholder="Bazza" [(ngModel)]="person.preferredName"
//             disabled="{{getPreferredNameDisabled()}}">
//         <mat-hint>Common nickname or diminutive name for the person</mat-hint>
//     </mat-form-field>
//     <br>
//     <label id="birth-sex-label">Registered Sex at birth</label>
//     <mat-radio-group aria-labelledby="birth-sex-label" class="birthsex-radio-group"
//         [(ngModel)]="person.birthSex">
//         <mat-radio-button class="birthsex-radio-button" *ngFor="let sexOption of sexOptions"
//             [value]="sexOption">{{sexOption}}</mat-radio-button>
//     </mat-radio-group>
//     <br>
//     <mat-form-field *ngIf="person.dob=='Recorded'" appearance="fill" style="width:20vw">
//         <mat-label>Date of Birth</mat-label>
//         <input matInput placeholder="11-11-1911" [(ngModel)]="person.dob" required>
//         <mat-icon matPrefix>star</mat-icon>
//         <mat-hint>Date of Birth</mat-hint>
//     </mat-form-field>

//     <mat-form-field *ngIf="person.dob!='Recorded'" appearance="fill" style="width:20vw">
//         <mat-label>Date of Birth</mat-label>
//         <input matInput placeholder="11-11-1911" type="date" [(ngModel)]="person.dob" required>
//         <mat-icon *ngIf="!isDobDefined()" matPrefix>star_border</mat-icon>
//         <mat-icon *ngIf="isDobDefined()" matPrefix>star</mat-icon>
//     </mat-form-field>
//     &nbsp;
//     <mat-checkbox [(ngModel)]="living">Living</mat-checkbox>
//     &nbsp;
//     <mat-form-field *ngIf="living==false" appearance="fill" style="width:20vw">
//         <mat-label>Date of Death</mat-label>
//         <input matInput placeholder="1911-11-11" type="date" [(ngModel)]="person.dod">
//     </mat-form-field>
//     <br>
// </mat-card-content>
// </mat-card>




