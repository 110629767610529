<div class="blob">
  <mat-card>
    <mat-card-content>
      <mat-card-title>People</mat-card-title>
      <mat-form-field appearance="fill" style="width:220px">
        <mat-label>Family Name</mat-label>
        <input matInput placeholder="Dean" (change)="OnFilterChange()" [(ngModel)]="filterName">
        <mat-hint>Match family name for the person</mat-hint>
      </mat-form-field>
      &nbsp;
      <mat-form-field  appearance="fill" style="width:220px">
        <mat-label>Registered Sex at birth</mat-label>
        <mat-select [(ngModel)]="filterBirthSex" name="sex">
          <mat-option *ngFor="let sexOption of sexOptions" [value]="sexOption">
            {{sexOption}}
          </mat-option>
        </mat-select>
        <mat-hint>Sex to filter or select All</mat-hint>
      </mat-form-field>

      <br>

      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="onFilter()">
          <mat-icon>filter_list</mat-icon>Apply Filter
        </button>
        <button mat-raised-button color="warn" (click)="onReset()">
          <mat-icon>clear</mat-icon>Reset filter
        </button>
        &nbsp;&nbsp;
        <button mat-raised-button color="accent" (click)="onNewPerson()">
          <mat-icon>person_add</mat-icon>Add Person
        </button>
      </mat-card-actions>
    </mat-card-content>
    <mat-card-footer *ngIf="fetchingStatus">{{fetchingStatus}}</mat-card-footer>
  </mat-card>
</div>

<div *ngIf="peoples" class="blob">
  <table mat-table [dataSource]="peoples" class="mat-elevation-z8">

    <!-- Link column-->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let peep">
        <button mat-button matTooltip="Edit person" matSuffix mat-icon-button aria-label="modify" (click)="gotoPeep(peep.id)">
          <mat-icon>person</mat-icon>
        </button>
        <button mat-button matTooltip="Make Favourite" matSuffix mat-icon-button aria-label="itsMe" (click)="itsMe(peep.id)">
          <mat-icon>{{makeFavourite(peep.id)}}</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef> First Name </th>
      <td mat-cell *matCellDef="let peep"> {{peep.firstName}} </td>
    </ng-container>

    <!-- Family Name Column -->
    <ng-container matColumnDef="familyName">
      <th mat-header-cell *matHeaderCellDef> Family Name </th>
      <td mat-cell *matCellDef="let peep"> {{peep.familyName}} </td>
    </ng-container>

    <!-- DOB Column -->
    <ng-container matColumnDef="dob">
      <th mat-header-cell *matHeaderCellDef> Date of Birth </th>
      <td mat-cell *matCellDef="let peep"> {{fmtDateStr(peep.dob)}} </td>
    </ng-container>

    <!-- Complete Column -->
    <ng-container matColumnDef="complete">
      <th mat-header-cell *matHeaderCellDef> Complete </th>
      <td mat-cell *matCellDef="let peep">
        <mat-icon class="mat-icon2">{{getPeepCompChk(peep, 0)}}</mat-icon>
        <mat-icon class="mat-icon2">{{getPeepCompChk(peep, 1)}}</mat-icon>
        <mat-icon class="mat-icon2">{{getPeepCompChk(peep, 2)}}</mat-icon>
        <mat-icon class="mat-icon2">{{getPeepCompChk(peep, 3)}}</mat-icon>
        <mat-icon class="mat-icon2">{{getPeepCompChk(peep, 4)}}</mat-icon>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>