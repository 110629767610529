import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  path: string;
  rawPath: string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.rawPath = window.location.pathname;

    this.path = this.route.snapshot.url[0].path;
  }

  onList(): void {
    console.info("onList");
    this.router.navigate(['/list']); 
  }

  onBirthday(): void{
    console.info("OnBirthday");
    this.router.navigate(['/birthdays']); 
  } 

}
