<!-- Top bar -->
<div class="topbar" role="banner">
  <h1>
    {{title}}
  </h1>
  <div style="position:absolute;right:25px">
    <a mat-raised-button color="primary" href="/">
      <mat-icon>home</mat-icon>
    </a>
  </div>
  <div class="spacer"></div>
</div>

<div>
  <router-outlet></router-outlet>
</div>